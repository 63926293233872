import {
  FormControl as MuiFormControl,
  FormHelperText as MuiFormHelperText,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  Select as MuiSelect,
  SelectChangeEvent as MuiSelectChangeEvent,
  SelectProps as MuiSelectProps,
} from '@mui/material';

export interface SelectProps<T> extends MuiSelectProps<T> {
  fullWidth?: boolean;
  helperText?: string;
  changeEvent?: (v: T) => void;
}

export const Select = <T extends unknown>({ helperText, ...rest }: SelectProps<T>) => {
  const handleChange = (event: SelectChangeEvent<T>) => {
    rest.changeEvent?.(event.target.value as T);
  };

  return (
    <MuiFormControl variant={rest.variant || 'standard'} fullWidth={rest.fullWidth}>
      {rest.label && <MuiInputLabel id={rest.labelId}>{rest.label}</MuiInputLabel>}
      <MuiSelect {...rest} onChange={rest.onChange || handleChange} />
      {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
    </MuiFormControl>
  );
};

export const MenuItem = ({ ...rest }: MuiMenuItemProps) => <MuiMenuItem {...rest} />;

export type SelectChangeEvent<T> = MuiSelectChangeEvent<T>;

import { HTMLAttributes, ReactNode } from 'react';
import { useAutoId } from 'src/hooks/useAutoId';
import { Chip } from 'src/components/mui-components/Chip';
import { Paper } from 'src/components/mui-components/Paper';
import { Typography } from 'src/components/mui-components/Typography';
import styles from './FilterTag.module.scss';

const ChipTextComponent = ({ label, list }: { label: string; list: FilterTagItemProp[] }) => (
  <Typography component="span" sx={{ fontSize: 13 }}>
    {label}: <span data-automation-id="FilterTagOpenPanelButtonValue">{list[0]?.label} </span>
    {list.length > 1 && ` +${list.length - 1}`}
  </Typography>
);

export interface FilterTagProps extends Omit<HTMLAttributes<HTMLDivElement>, 'style'> {
  title: string;
  items: FilterTagItemProp[];
  children: ReactNode;
  removeFilterTag?: () => void;
  panelIsOpen?: boolean;
  onClick: () => void;
}

export interface FilterTagItemProp {
  label: string;
}

export const FilterTag = ({
  title,
  items,
  removeFilterTag,
  children,
  panelIsOpen = false,
  onClick,
  ...props
}: FilterTagProps) => {
  const autoId = `filter-${useAutoId()}`;
  return (
    <div className={styles.tagContainer} {...props}>
      <Chip
        color="primary"
        label={<ChipTextComponent label={title} list={items} />}
        onClick={onClick}
        onDelete={removeFilterTag || undefined}
        data-automation-id="FilterTagOpenPanelButton"
        aria-controls={autoId}
        aria-expanded={panelIsOpen}
      />
      <Paper
        id={autoId}
        className={styles.panelContent}
        data-automation-id="FilterTagPanelContent"
        hidden={!panelIsOpen}
      >
        {children}
      </Paper>
    </div>
  );
};

export default FilterTag;

import { RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { IconButton, Stack, Typography } from 'src/components/mui-components';

import CloseIcon from '@mui/icons-material/Close';

import styles from './Header.module.scss';

interface HeaderProps {
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
}

const Header = ({ activateSidePanelButtonRef }: HeaderProps) => {
  const dispatch = useSidePanelDispatch();
  const { t } = useTranslation('filter');

  return (
    <Stack
      className={styles.title}
      data-automation-id="FilterTitle"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography
        fontWeight={500}
        component="h2"
        variant="h2"
        data-automation-id="SidePanelHeading"
      >
        {t('FilterPanelHeading')}
      </Typography>
      <IconButton
        data-automation-id="SidePanelCloseBtn"
        title={t('FilterPanelCloseButton')}
        onClick={() => {
          if (dispatch) dispatch({ type: 'CLOSE_PANEL' });

          if (activateSidePanelButtonRef?.current) {
            activateSidePanelButtonRef.current.focus();
          }
        }}
      >
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default Header;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

// --------------------
// GENERIC TYPES
// --------------------

export type As<Props = any> = React.ElementType<Props>;
// type Test = As<{ prop: string }>

export type PropsWithAs<Props = Record<string, unknown>, Type extends As = As> = Props &
  Omit<React.ComponentProps<Type>, 'as' | keyof Props> & {
    as?: Type;
  };

export type ComponentWithAs<Props, DefaultType extends As> = {
  <Type extends As>(props: PropsWithAs<Props, Type> & { as: Type }): JSX.Element;
  (props: PropsWithAs<Props, DefaultType>): JSX.Element;
};

// --------------------
// UTILS
// --------------------

export function forwardRefWithAs<Props, DefaultType extends As>(
  component: React.ForwardRefRenderFunction<any, any>,
): any {
  return React.forwardRef(component) as unknown as ComponentWithAs<Props, DefaultType>;
}

export type DataAutomationId = {
  /** Type to be used for an unique id that we can target in tests and digital adoption
   *  for onboarding guides. Must be unique within the page and descriptive to know what it does.
   *  Must be PascalCase.
   * @example 'SidebarFilterListButton'
   *
   */
  'data-automation-id': string;
};

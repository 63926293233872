import { Autocomplete } from 'src/components/mui-components';
import { DataAutomationId } from 'src/utils/ts-utils';

export interface IFilterSelectOnChangeEvent {
  label?: string;
  value: string;
}
export type TFilterType = 'MultiSelectTypeSearch';

export interface IFilterItemProperties<U>
  extends Partial<Omit<HTMLFormElement, 'contentEditable'>>,
    DataAutomationId {
  fieldLabel: string;
  filterId: string;
  options?: Array<IFilterSelectOnChangeEvent>;
  defaultValue?: U;
  hiddenLabel?: boolean;
}

export type TFilterItemProperties = IFilterItemProperties<Array<IFilterSelectOnChangeEvent>>;

const FilterInput = ({
  fieldLabel,
  filterId,
  addFilterItem,
  options = [],
  defaultValue,
  ...restProps
}: TFilterItemProperties) => (
  <Autocomplete
    fullWidth
    multiple
    options={options}
    defaultValue={defaultValue}
    disableCloseOnSelect
    disablePortal
    componentsProps={{
      popper: {
        modifiers: [
          {
            // We add this to prevent the popper from hiding the "is" and "is not" radio buttons
            name: 'flip',
            enabled: false,
          },
        ],
      },
    }}
    {...restProps}
    data-automation-id={restProps['data-automation-id']}
  />
);

export default FilterInput;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetProjectEconomyHealthReportingWidget } from 'src/apis/reportingWidgetsAPI';
import { IProjectEconomyHealthWidgetData } from 'src/apis/types/projectEconomyHealthAPI';
import { Chip, GridRenderCellParams } from 'src/components/mui-components';
import { ProgressBar } from 'src/components/ui-components';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import ReportingWidgetWithTable from '../ReportingWidgetWithTable';

const parseRowData = (data: IProjectEconomyHealthWidgetData[], locale: string) => {
  const newRow = data.map((item) => ({
    customer: item.customerName,
    project: item.projectName,
    sumBudgetHours: item.sumBudgetHours.toLocaleString(locale),
    sumHoursSpent: item.sumHoursSpent.toLocaleString(locale),
    hoursRemaining: item.hoursRemaining.toLocaleString(locale),
    completionRate:
      item.completionRate === 'NaN' || item.completionRate === 'Infinity'
        ? 0
        : Number(item.completionRate),
    status: item.progressStatus.toLowerCase(),
  }));

  return newRow;
};

export const ProjectEconomyHealthReportingWidget = () => {
  const locale = useGetLocale();
  const { t } = useTranslation('reportingWidgets');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 15,
  });
  const { widgetName, widgetData, paginationInfo, isError, isLoading } =
    useGetProjectEconomyHealthReportingWidget(paginationModel);

  const getColumnData = () => {
    const columnData = [
      {
        headerName: t('TableHeaderProject'),
        key: 'project',
        flex: 2,
      },
      {
        headerName: t('TableHeaderCustomer'),
        key: 'customer',
        flex: 2,
      },
      {
        headerName: t('TableHeaderHoursBudget'),
        key: 'sumBudgetHours',
        flex: 2,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) =>
          Number(params.value) === 0 ? (
            <div data-automation-id="NoBudgetChip">
              <Chip color="default" label={t('LabelNoBudget')} />
            </div>
          ) : (
            params.value
          ),
      },
      {
        headerName: t('TableHeaderHoursSpent'),
        key: 'sumHoursSpent',
        flex: 1.5,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params: GridRenderCellParams) =>
          Number(params.row.sumHoursSpent) === 0 ? (
            <span data-automation-id="NoHoursSpent" />
          ) : (
            params.value
          ),
      },
      {
        headerName: t('TableHeaderHoursRemaining'),
        key: 'hoursRemaining',
        flex: 2,
        headerAlign: 'right',
        align: 'right',
        cellClassName: (params: GridRenderCellParams) => params.row.status,
        renderCell: (params: GridRenderCellParams) =>
          Number(params.row.sumBudgetHours) === 0 || Number(params.row.sumHoursSpent) === 0 ? (
            <span data-automation-id="NoBudgetOrHoursSpent" />
          ) : (
            params.value
          ),
      },
      {
        headerName: t('TableHeaderCompletionRate'),
        key: 'completionRate',
        flex: 4,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => (
          <ProgressBar
            barStatus={params.row.status}
            label={`${params.row.project}-Completion`}
            value={params.value}
          />
        ),
      },
    ];

    return columnData;
  };

  return (
    <ReportingWidgetWithTable
      name={widgetName}
      title={t('ProjectEconomyHealth')}
      data={parseRowData(widgetData, locale)}
      columnData={getColumnData()}
      infoIconText={t('ProjectEconomyHealthWidgetDescription')}
      hasPagination
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      rowCount={Number(paginationInfo.totalRecord)}
      noDataMessage={t('ProjectEconomyHealthEmptyMessage')}
      isEmpty={widgetData.length <= 0}
      isError={isError}
      isLoading={isLoading}
    />
  );
};

export default ProjectEconomyHealthReportingWidget;

import { forwardRef } from 'react';
import { Paper as MuiPaper, PaperProps } from '@mui/material';

type PaperRef = HTMLDivElement;

export const Paper = forwardRef<PaperRef, PaperProps>(({ ...rest }, ref) => (
  <MuiPaper ref={ref} {...rest} />
));

export type { PaperProps };

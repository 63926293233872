import { forwardRef } from 'react';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { Tooltip } from 'src/components/mui-components';

type IconButtonRef = HTMLButtonElement & {
  title: string;
};

export const IconButton = forwardRef<IconButtonRef, MuiIconButtonProps>(
  ({ title, ...rest }, ref) => (
    <Tooltip title={title}>
      <MuiIconButton ref={ref} {...rest} />
    </Tooltip>
  ),
);

export type IconButtonProps = MuiIconButtonProps;

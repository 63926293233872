import { ReactNode, RefObject, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Button, Stack, Badge, Typography, Paper } from 'src/components/mui-components';

interface FilterContainerProps {
  openFilterPanel: () => void;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  activeFilters: number;
  clearAllFilters?: () => void;
  children?: ReactNode;
  searchInput?: ReactNode;
  secondaryActions?: ReactNode;
  filterButtonText?: string;
  noActiveFiltersText?: string;
  addFilterButtonText?: string;
  clearAllFiltersButtonText?: string;
  initialActivePanelIsOpen?: boolean;
  localStorageNamePrefix: string;
}

export const FilterContainer = ({
  openFilterPanel,
  activateSidePanelButtonRef,
  clearAllFilters,
  children,
  searchInput,
  activeFilters,
  secondaryActions,
  filterButtonText = 'Filters',
  noActiveFiltersText = 'Add filters to narrow down results',
  addFilterButtonText = 'Add filter',
  clearAllFiltersButtonText = 'Clear filters',
  initialActivePanelIsOpen = false,
  localStorageNamePrefix = '',
}: FilterContainerProps) => {
  const localStoragePrefix = `${localStorageNamePrefix}-`;
  const filterIsOpenLocal = localStorage.getItem(`${localStoragePrefix}filter-is-open`) === 'true';
  const [filterIsOpen, setfilterisOpen] = useState<boolean>(filterIsOpenLocal || false);
  useEffect(() => {
    localStorage.setItem(`${localStoragePrefix}filter-is-open`, JSON.stringify(filterIsOpen));
  }, [filterIsOpen, localStoragePrefix]);

  const [activeFiltersPanelIsOpen, setActiveFiltersPanelIsOpen] = useState(
    filterIsOpenLocal || initialActivePanelIsOpen,
  );
  const showResetButton = clearAllFilters && activeFilters;

  const toggleFilterPanel = () => {
    setfilterisOpen(!filterIsOpen);
    setActiveFiltersPanelIsOpen((prev) => !prev);
  };

  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={searchInput ? 'space-between' : 'flex-end'}
      >
        {searchInput}

        <Stack direction="row">
          <Button
            variant="text"
            startIcon={
              <Badge
                badgeContent={activeFilters}
                color="primary"
                data-automation-id="FilterContainerAddFilterCount"
              >
                <FilterListIcon />
              </Badge>
            }
            size="large"
            onClick={() => toggleFilterPanel()}
            aria-controls="active-filters-container"
            aria-expanded={activeFiltersPanelIsOpen}
            data-automation-id="FilterContainerToggleFilter"
          >
            {filterButtonText}
          </Button>
          {secondaryActions}
        </Stack>
      </Stack>

      <Paper
        id="active-filters-container"
        hidden={!activeFiltersPanelIsOpen}
        data-automation-id="FilterContainerActiveFiltersContainer"
        variant="outlined"
        sx={{ padding: 2 }}
      >
        <Stack
          direction="row"
          alignItems={activeFilters ? 'flex-start' : 'center'}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
            {activeFilters ? (
              children
            ) : (
              <Typography
                component="p"
                fontStyle="italic"
                data-automation-id="FilterContainerNoResultsText"
              >
                {noActiveFiltersText}
              </Typography>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap={2}>
            <Button
              variant="text"
              startIcon={<AddIcon />}
              size="small"
              onClick={openFilterPanel}
              data-automation-id="FilterContainerAddFilter"
              ref={activateSidePanelButtonRef}
            >
              {addFilterButtonText}
            </Button>
            {showResetButton ? (
              <Button
                variant="text"
                startIcon={<CloseIcon />}
                size="small"
                onClick={clearAllFilters}
                data-automation-id="FilterContainerRemoveFilters"
              >
                {clearAllFiltersButtonText}
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default FilterContainer;

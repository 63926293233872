import { HTMLAttributes, useContext } from 'react';
import '@reach/menu-button/styles.css';
import cx from 'classnames';
import { MenuItem, MenuLink } from '@reach/menu-button';

import { Flex } from 'src/components/ui-components/Flex';
import { Icon } from 'src/components/ui-components/Icon';
import { Modal, ModalContext } from 'src/components/ui-components/Modal';
import { IconButton } from 'src/components/ui-components/IconButton';
import {
  ContextMenuItemInnerInterface,
  ContextDropDownInterface,
  ContextMenuLinkInterface,
  ContextMenuItemInterface,
  ContextMenuModalInterface,
} from './ContextMenuType';
import { ContextMenu } from './ContextMenu';
import styles from './ContextMenu.module.scss';

const ItemInner = ({ iconName, position = 'left', ...props }: ContextMenuItemInnerInterface) => (
  // Need to use span as div is not allowed inside button element
  <Flex
    gap="xxSmall"
    as="span"
    direction={position === 'right' ? 'rowReverse' : 'row'}
    horizontalAlignment={position === 'right' ? 'right' : 'left'}
  >
    {iconName && (
      <Icon
        iconName={iconName}
        aria-hidden="true"
        data-automation-id={`ContextMenuItemIcon${position}`}
      />
    )}
    <Flex.Item as="span">{props.children}</Flex.Item>
  </Flex>
);

const ItemLink = ({
  wrapText,
  iconName,
  position,
  children,
  ...restProps
}: ContextMenuLinkInterface) => (
  <MenuLink
    className={cx(styles.ContextMenu__link, {
      [styles.ContextMenu__link____wrapText]: wrapText,
    })}
    {...restProps}
  >
    <ItemInner iconName={iconName} position={position}>
      {children}
    </ItemInner>
  </MenuLink>
);

const ItemButton = ({ iconName, position, children, ...restProps }: ContextMenuItemInterface) => (
  <MenuItem as="button" className={styles.ContextMenu__link} {...restProps}>
    <ItemInner iconName={iconName} position={position}>
      {children}
    </ItemInner>
  </MenuItem>
);

const ModalMenuItem = ({
  iconName,
  position,
  size,
  headingText,
  closeText,
  headingComponent,
  childComponent,
  children,
  scrollInContent,
  ...props
}: ContextMenuModalInterface) => {
  const { openModal } = useContext(ModalContext);
  return (
    <>
      <MenuItem as="button" className={styles.ContextMenu__link} {...props} onSelect={openModal}>
        <ItemInner iconName={iconName} position={position}>
          {children}
        </ItemInner>
      </MenuItem>

      <Modal.Dialog size={size} scrollInContent={scrollInContent}>
        <Modal.Header heading={headingText} closeText={closeText}>
          {headingComponent}
        </Modal.Header>
        {/* Needs to have <Modal.Content> or <Modal.Footer> for spacing */}
        {childComponent}
      </Modal.Dialog>
    </>
  );
};

const ItemModal = (props: ContextMenuModalInterface) => (
  <Modal>
    <ModalMenuItem {...props} />
  </Modal>
);

export const ContextMenuWithListItems = ({
  as = IconButton,
  iconName = 'moreVertical',
  buttonSize = 'default',
  listItems,
  text,
  ...props
}: ContextDropDownInterface) =>
  listItems && listItems.length > 0 ? (
    <ContextMenu>
      <ContextMenu.Trigger as={as} iconName={iconName} size={buttonSize} {...props}>
        {text}
      </ContextMenu.Trigger>
      <ContextMenu.List>
        {listItems?.map(({ key, type, visible = true, ...restProps }) => {
          switch (type) {
            case 'link':
              return visible && <ItemLink key={key} {...(restProps as ContextMenuLinkInterface)} />;
            case 'modal':
              return (
                visible && <ItemModal key={key} {...(restProps as ContextMenuModalInterface)} />
              );
            case 'button':
              return (
                visible && <ItemButton key={key} {...(restProps as ContextMenuItemInterface)} />
              );
            case 'divider':
              return (
                visible && (
                  <ContextMenu.Divider
                    key={key}
                    {...(restProps as HTMLAttributes<HTMLHRElement>)}
                  />
                )
              );
            default:
              return null;
          }
        })}
      </ContextMenu.List>
    </ContextMenu>
  ) : null;

import { IFilterCategoryProperties, IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { Stack } from 'src/components/mui-components/Stack';
import { Typography } from 'src/components/mui-components/Typography';
import { List } from 'src/components/mui-components/List';
import Input from '../Input';
import styles from './InputGroup.module.scss';

interface IRenderFilterList {
  filterItemList?: Array<IFilterItemProperties>;
}

const RenderInputList = ({ filterItemList = [] }: IRenderFilterList) => (
  <>
    {filterItemList.map(({ id, ...filterItem }) => (
      <Input key={id} id={id} {...filterItem} />
    ))}
  </>
);

const InputGroup = ({ filterCategory, filterItems }: IFilterCategoryProperties) => (
  <Stack gap={1} key={filterCategory} className={styles.groupList}>
    <div className={styles.groupTitle}>
      <Typography
        key={filterCategory}
        component="h3"
        variant="h3"
        fontWeight={600}
        data-automation-id={`GroupHeading${filterCategory}`}
      >
        {filterCategory}
      </Typography>
    </div>
    <List>
      <RenderInputList filterItemList={filterItems} />
    </List>
  </Stack>
);

export default InputGroup;
